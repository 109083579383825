import { Event, DayOfWeek, MovaInterval, Schedule, EventState, VehicleTire, Prestation, PartsApplicationType, Logger } from '@movalib/movalib-commons';
import { COOKIE_DEFAULT_EXPIRES, COOKIE_IS_AUTHENTICATED, COOKIE_STOP_PUSH_ADD_VEHICLE, COOKIE_USER_ID, WEEK_DAYS } from './Constants';
import Cookies from 'js-cookie';
import { CSSProperties } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import theme from '../theme';
import { AlertColor, darken, lighten } from '@mui/material';
import { COOKIE_INDIVIDUAL_TOKEN } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';
import moment from 'moment-timezone';

export  const importIcon = (iconName: string): string => {
  try {
    const lowerCaseIconName = iconName.toLowerCase();
    return require(`../assets/images/icons/${lowerCaseIconName}.png`);
  } catch (_error) {
    //console.error(`Icon not found: ${iconName}.png`);
    return ''; // or a path to a default icon
  }
};
/**
 * Formater les dates en chaînes de caractères qui reflètent le fuseau horaire local sans conversion.
 * Idéal pour transmettre les dates avec le fuseau horaire à l'API lors d'un appel à JSON.stringify
 * TODO : rendre dynamique le timeZone en paramètre
 * @param date 
 * @returns 
 */
export function formatLocalDateToISOString(date: Date, timeZone: string = 'Europe/Paris'): string {
  return moment(date).tz(timeZone).format('YYYY-MM-DDTHH:mm:ssZ') + '[' + timeZone + ']';
}

export const formatEurosCurrency = (number:number) => {
  return `${number.toLocaleString('fr-FR')} €`;
}

/**
 * Recherche au sein de la liste des opérations d'une prestation
 * s'il existe au moins une application de définit. Dans ce cas, cela signifie
 * qu'il faut choisir l'application pour cette prestation
 * @param prestation 
 * @returns 
 */
export const applicationChoiceRequired = (prestation: Prestation | undefined):boolean => {

  if(prestation && prestation.operations){
    // Si au moins une opérations dans cette prestation contient une application, alors il faut choisir
    return prestation.operations?.filter(o => o.application).length > 0;
  }

  return false;
}

/**
 * Retourne la "severity" en fonction d'un statut d'événement
 * Peut-être utilisé pour l'affichage d'une alerte par exemple
 * AlertColor : 'success' | 'info' | 'warning' | 'error'
 * @param state
 * @returns 
 */
export function getAlertSeverity(state: EventState): AlertColor {
  switch(state){
    case EventState.NEW : return "info";
    case EventState.ACCEPTED : return "info";
    case EventState.REJECTED : return "info";
    case EventState.SCHEDULED :
    case EventState.COMPLETED :
    case EventState.DONE :  return "success"
    case EventState.CANCELLED : return "warning";
  }
}

export function getEventStateColor(state: EventState): string {
  switch(state){
    case EventState.NEW : return theme.palette.grey[400];
    // Accepté par le garagiste, devis à confirmer la cas échéant
    case EventState.ACCEPTED : 
    case EventState.REJECTED : return '#F29ABA';
    case EventState.SCHEDULED :
    // L'état COMPLETED est utile uniquement pour le Garage (aide mémoire permettant d'indiquer la dispos pièces)
    case EventState.COMPLETED : return theme.palette.primary.main;
    case EventState.DONE : return lighten(theme.palette.info.main, 0.6);
    case EventState.CANCELLED : return darken(theme.palette.warning.main, 0.1);
  }
}

export function getEventStateLabel(state: EventState) : string {
  switch(state){
    case EventState.NEW : return "En attente confirmation garage ...";
    // Accepté par le garagiste, devis à confirmer la cas échéant
    case EventState.ACCEPTED : return "Devis disponible, à vous de jouer !";
    // Devis refusé par le client
    case EventState.REJECTED : return "Devis refusé";
    case EventState.SCHEDULED :
    // L'état COMPLETED est utile uniquement pour le Garage (aide mémoire permettant d'indiquer la dispos pièces)
    case EventState.COMPLETED : return "Rendez-vous confirmé !"
    case EventState.DONE : return "Rendez-vous clôturé";
    case EventState.CANCELLED : return "Rendez-vous annulé";
  }
}

export function formatVehicleTire(vehicleTire: VehicleTire){
  if(vehicleTire){
    let concatened = `${vehicleTire.width}${vehicleTire.height}${vehicleTire.diameter}${vehicleTire.speedIndex}`;
    return formatVehicleTireStr(concatened);
  }
  return '';
}

export function formatVehicleTireStr(input: string){

  let formatted = input.toUpperCase().replace(/[^0-9A-QS-Za-qs-z]/g, '').slice(0, 10);

  if (formatted.length >= 3) {
    formatted = `${formatted.substring(0, 3)} / ${formatted.substring(3)}`;
  }

  const indexOfR = formatted.indexOf(" R");

  if (formatted.length >= 8 && indexOfR === -1) {
    formatted = `${formatted.substring(0, 8)} R${formatted.substring(8)}`;

    if (formatted.length >= 12 && indexOfR === -1)
      formatted = `${formatted.substring(0, 12)} ${formatted.substring(12)}`;

  } else if (indexOfR !== -1 && formatted.length > indexOfR + 2) {
    formatted = `${formatted.substring(0, indexOfR + 2)} ${formatted.substring(indexOfR + 2)}`;
  }

  return formatted;
};

export function isValidatedAppointment(appointment:Event) {
  if(appointment){
      return appointment.state === EventState.ACCEPTED
      || appointment.state === EventState.COMPLETED
      || appointment.state === EventState.SCHEDULED;
  }
}

export function isCancellableAppointment(appointment:Event) {
  if(appointment){
      return appointment.state === EventState.NEW
      || appointment.state === EventState.ACCEPTED
      || appointment.state === EventState.COMPLETED
      || appointment.state === EventState.SCHEDULED;
  }
}

export function getRotatedIconStyle() : CSSProperties {
  return {
    position: 'relative',
    width: '100%',
    top: '-20px',
    zIndex: 200,
    left: '-49%',
    height: '40px',
    marginBottom: '-30px',
    transform: 'rotate(-20deg)'
  }
}

export function getLongFormattedDateTime(date: Date | undefined){
  if(date){
    const day = capitalizeFirstLetter(format(date, 'eeee', { locale: fr }));
    const month = capitalizeFirstLetter(format(date, 'MMMM', { locale: fr }));
    const hours = format(date, 'HH', { locale: fr });
    const minutes = format(date, 'mm', { locale: fr });
    const dayOfMonth = format(date, 'dd');

    return `${day} ${dayOfMonth} ${month} à ${hours}:${minutes}`;
  }
  return '';
}

export function getLongFormattedDate(date: Date){
  if(date){
    const day = capitalizeFirstLetter(format(date, 'eeee', { locale: fr }));
    const month = capitalizeFirstLetter(format(date, 'MMMM', { locale: fr }));
    const dayOfMonth = format(date, 'dd');

    return `${day} ${dayOfMonth} ${month}`;
  }
  return '';
}

export function capitalizeFirstLetter(str: string): string {
  if (str.length === 0) {
    return str;
  }

  const firstChar = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1);

  return firstChar + restOfString;
}

export function createDateWithTime(date: Date, time: string): Date {
  if(date && time){

    const [hours, minutes] = time.split(":").map(Number);
    const newDate = new Date(date);
    newDate.setHours(hours, minutes, 0, 0);

    return newDate;
  }

  return date;
}

export const mergeMaps = (existingMap: Map<Date, string[]>, newMap: Map<Date, string[]>) => {
  if(existingMap && newMap){
    newMap.forEach((value, key) => {
      const existingValue = existingMap.get(key);
      if (existingValue) {
        // Fusionner les tableaux si la clé existe déjà
        existingMap.set(key, [...existingValue, ...value]);
      } else {
        // Ajouter la nouvelle paire clé-valeur sinon
        existingMap.set(key, value);
      }
    });
  }
};

export const getLastAvailableDate = (dateMap: Map<Date, string[]>): Date | null => {
  if(dateMap){
    const dateKeys = Array.from(dateMap.keys());

    if (dateKeys.length === 0) {
      return null;
    }
  
    // Trier les dates
    dateKeys.sort((a, b) => a.getTime() - b.getTime());
  
    // Prendre la dernière date
    const lastDate = dateKeys[dateKeys.length - 1];

    return lastDate;
  }

  return null;
};

export const objectToMap = (obj: any): Map<Date, string[]> => {
  const map = new Map();
  for (const [key, value] of Object.entries(obj)) {
    map.set(new Date(key), value);
  }
  return map;
};

export const flexStart:CSSProperties = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start'
}

export const flexCenter:CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  let formattedNumber = "";

  if(phoneNumber){
    for (let i = 0; i < phoneNumber.length; i++) {
      if (i > 0 && i % 2 === 0) {
        formattedNumber += ".";
      }
      formattedNumber += phoneNumber[i];
    }
  }

  return formattedNumber;
};

const sortMovaIntervals = (intervals: MovaInterval[]): MovaInterval[] => {
  if(intervals){
    return intervals.sort((a, b) => {
      // Convertir en Date si nécessaire
      const startTimeA = a.startTime instanceof Date ? a.startTime : new Date(a.startTime as string);
      const startTimeB = b.startTime instanceof Date ? b.startTime : new Date(b.startTime as string);
  
      // Gérer les valeurs nulles
      if (startTimeA === null && startTimeB === null) return 0;
      if (startTimeA === null) return 1;
      if (startTimeB === null) return -1;
  
      // Comparer les dates
      return startTimeA.getTime() - startTimeB.getTime();
    });
  }
  return [];
}

export const findScheduleByDayOfWeek = (schedules: Schedule[], dayIndex: number): Schedule | null => {
  const foundSchedule = schedules.find(schedule => schedule.dayOfWeek === getDayOfWeek(dayIndex));
  return foundSchedule ? foundSchedule : null;
};

export const getFormattedSchedule = (schedule: Schedule | null, dayIndex: number) => {
  if(schedule){
    return `${getFrenchDayLabel(schedule.dayOfWeek)} :   ${getFormattedIntervals(schedule.intervals)}`
  } else {
    return `${getFrenchDayLabel(getDayOfWeek(dayIndex))} :   fermé`;
  }
}

export const getDayOfWeek = (index:number) => {
  switch (index) {
    case 0 : return DayOfWeek.MONDAY;
    case 1 : return DayOfWeek.TUESDAY;
    case 2 : return DayOfWeek.WEDNESDAY;
    case 3 : return DayOfWeek.THURSDAY;
    case 4 : return DayOfWeek.FRIDAY;
    case 5 : return DayOfWeek.SATURDAY;
    case 6 : return DayOfWeek.SUNDAY;
  }
}

export const getDayIndex = (day:DayOfWeek) => {
  switch (day) {
    case DayOfWeek.MONDAY: return 0;
    case DayOfWeek.TUESDAY: return 1;
    case DayOfWeek.WEDNESDAY: return 2;
    case DayOfWeek.THURSDAY: return 3;
    case DayOfWeek.FRIDAY: return 4;
    case DayOfWeek.SATURDAY: return 5;
    case DayOfWeek.SUNDAY: return 6;
  }
}

export const getFrenchDayLabel = (day:DayOfWeek | undefined) => {
  switch (day) {
    case DayOfWeek.MONDAY: return 'Lundi';
    case DayOfWeek.TUESDAY: return 'Mardi';
    case DayOfWeek.WEDNESDAY: return 'Mercredi';
    case DayOfWeek.THURSDAY: return 'Jeudi';
    case DayOfWeek.FRIDAY: return 'Vendredi';
    case DayOfWeek.SATURDAY: return 'Samedi';
    case DayOfWeek.SUNDAY: return 'Dimanche';
  }
}

export const getFormattedIntervals = (intervals: MovaInterval[]) => {
  if(intervals){
    let times:string = "";
    sortMovaIntervals(intervals).map((interval:MovaInterval, index) => {
      if(index > 0){
        times = `${times} |  `; 
      }
      times = `${times} ${formatTime(interval.startTime)} - ${formatTime(interval.endTime)}`
    })
    return times;
  }
}

export const formatTime = (date: string | Date | null) => {
  if(date){
    let strDate:string = date.toString();
    return strDate.substring(0, 5);
  }
};

export const deleteCookie = (name:string) => {
  if(name){
    Cookies.remove(name);
  }
}

export const readCookie = (name:string) => {
  if(name){
    return Cookies.get(name);
  }
}

/**
 * Création d'un cookie.
 * expires : nombre de jours avant expiration
 * sameSite : 'None', ce qui signifie que le cookie sera envoyé même lors de requêtes intersites TODO : réactiver !
 * secure : pour s'assurer que le cookie n'est envoyé que sur une connexion sécurisée (HTTPS)
 * @param name : nom du cookie
 * @param value : valeur du cookie
 */
export const createCookie = (name:string, value:string) => {
  if(name && value){
    Cookies.set(name, value, { expires: COOKIE_DEFAULT_EXPIRES, sameSite: 'None', secure: true });
  }
}

export const deleteCookies = () => {
  Cookies.remove(COOKIE_IS_AUTHENTICATED);
  Cookies.remove(COOKIE_USER_ID);
  Cookies.remove(COOKIE_INDIVIDUAL_TOKEN);
  Cookies.remove(COOKIE_STOP_PUSH_ADD_VEHICLE);
}

export const getDaysDiff = (start:Date, end:Date): number => {
  if(start && end){
    return  Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
  }

  return 0;
}

/**
 * Prend un Objet de type <string, string> et la clé recherchée en tant que paramètres. 
 * Elle utilise Object.keys pour obtenir toutes les clés de l'objet, 
 * puis Array.find pour trouver la clé correspondante.
 * @param obj 
 * @param key 
 * @returns Si une correspondance est trouvée, 
 * la fonction renvoie la valeur correspondante à partir de l'objet. 
 * Si aucune correspondance n'est trouvée, elle renvoie undefined.
 */
export const findValueByKey = (obj: Record<string, string>, key: string): string | undefined => {
    const keys = Object.keys(obj);
    const foundKey = keys.find(k => k === key);
  
    if (foundKey) {
      return obj[foundKey];
    }
  
    return undefined;
};

export function isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
}