import { useState, type FC, CSSProperties, useEffect } from 'react';
import { Garage, Logger, MovaDialog, PartsApplicationType, Prestation, getApplicationShortLabel, getApplicationsShortLabels, readCookie } from '@movalib/movalib-commons';
import { Alert, Box, Button, Card, CardContent, Checkbox, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography, darken, styled, useMediaQuery, useTheme } from '@mui/material';
import QuestionIcon from '@mui/icons-material/HelpOutlineRounded';
import NextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { applicationChoiceRequired, flexCenter, flexStart, importIcon } from '../../helpers/Tools';
import AppointmentVehicleDialog from './AppointmentVehicleDialog';
import { PrestationRequest } from '../../helpers/Types';
import { COOKIE_INDIVIDUAL_TOKEN } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';
import AppointmentCustomerDialog from './AppointmentCustomerDialog';
import React from 'react';
import { PALETTE_THIRD_COLOR_LIGHT, PALETTE_THIRD_COLOR_MAIN } from '../../helpers/Constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AppointmentPrestationsDialogProps {
    open:boolean,
    garage: Garage,
    onClose: () => void;
}

const AppointmentPrestationsDialog: FC<AppointmentPrestationsDialogProps> = ({ open, garage, onClose }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [checkedPrestations, setCheckedPrestations] = useState<PrestationRequest[]>([]);
    const [otherReason, setOtherReason] = useState<string>('');
    const [localOpen, setLocalOpen] = useState<boolean>(open);
    const [openAppointmentVehicle, setOpenAppointmentVehicle] = useState<boolean>(false);
    const [openAppointmentCustomer, setOpenAppointmentCustomer] = useState<boolean>(false);
    const [openApplicationChoice, setOpenApplicationChoice] = useState<boolean>(false);
    const [selectedPrestation, setSelectedPrestation] = useState<Prestation | undefined>(undefined);
    const [selectedApplications, setSelectedApplications] = useState<PartsApplicationType[] | undefined>(undefined);
    const [otherChoiceId, setOtherChoiceId] = useState<number>(-1);

    // Si l'utilisateur n'est pas authentifié
    const isAuthenticated:boolean = Boolean(readCookie(COOKIE_INDIVIDUAL_TOKEN));

    useEffect(() => {
        setLocalOpen(open);
        // On récupère l'identifiant de la prestation "Autre ..."
        if(garage && garage.prestations){
            setOtherChoiceId(
                garage.prestations.filter(p => p.code === 'OTHER').map(p => p.id)[0]
            )
        }

    }, [open]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, prestationId: number) => {

        let foundedPrestation:Prestation | undefined = garage.prestations.find(p => p.id === prestationId);

        // Si la prestation nécessite le choix de l'application (selon les opérations sous-jacentes)
        if(event.target.checked && foundedPrestation && applicationChoiceRequired(foundedPrestation)){
            setSelectedPrestation(foundedPrestation);
            setSelectedApplications(undefined);
            setOpenApplicationChoice(true);
        } else {
            if (event.target.checked) {
                // On crée un nouvel objet PrestationRequest
                let req: PrestationRequest = {
                    id: prestationId,
                    code: foundedPrestation ? foundedPrestation.code : ''
                }
                // Cas particulier de choix "Autre ...", dans ce cas il n'y a que ce choix d'actif
                if(foundedPrestation?.id === otherChoiceId){
                    setCheckedPrestations([req]);
                } else {
                    setCheckedPrestations([...checkedPrestations, req]);
                }
            } else {
                // Cas particulier de choix "Autre ...", on purge l'éventuel otherReason
                if(foundedPrestation?.id === otherChoiceId){
                    setOtherReason('');
                }
                setCheckedPrestations(checkedPrestations.filter(req => req.id !== prestationId));
            }
        }
    };

    const purgeLocalState = () => {
        setCheckedPrestations([]);
        setSelectedPrestation(undefined);
        setOpenApplicationChoice(false);
    }

    const handleOnClose = () => {
        purgeLocalState();
        onClose();
    }

    const dialogTitleStyle: CSSProperties = {
        color: darken(theme.palette.primary.main, 0.2),
        fontWeight: 700
    }

    const handleOnClickVehicleChoice = () => {

        if(isAuthenticated){
            setOpenAppointmentVehicle(true);
        } else {
            setOpenAppointmentCustomer(true);
        }

        setLocalOpen(false);
    }

    const handleOnCloseAppointmentVehicle = () => {
        setOpenAppointmentVehicle(false);
        setLocalOpen(true);
    }

    const handleOnCloseAppointmentCustomer = () => {
        setLocalOpen(true);
        setOpenAppointmentCustomer(false);
    }

    const handleCancelApplicationChoice = () => {
        setOpenApplicationChoice(false);
        setSelectedApplications(undefined);
    }

    const handleMultipleApplicationsChoice = (value: PartsApplicationType[]) => {
        // Toggle the selection of the application
        setSelectedApplications(value);

        // Si la prestation sélectionnée n'est pas en multiple application, on confirme automatiquement le choix
        if(selectedPrestation?.operations && !selectedPrestation.multipleApplication){
            confirmApplicationsChoice(value);
        }
    };

    const confirmApplicationsChoice = (value: PartsApplicationType[] | undefined) => {

        // On choisit en priorité les applications transmises, sinon on exploite la valeur en useState
        const applications = value ?? selectedApplications;

        if (selectedPrestation && applications !== undefined) {

            Logger.info(applications)

            // On crée un nouvel objet PrestationRequest
            const req: PrestationRequest = {
                id: selectedPrestation.id,
                code: selectedPrestation.code,
                applications: applications,
            };
            setCheckedPrestations([...checkedPrestations, req]);
            // On maj le formulaire de saisie
            /* const newValue = [...form.prestations?.value, focusedPrestation.id];
            Logger.info(newValue);
            setForm((prevForm) => ({ ...prevForm, prestations: { ...prevForm['prestations'], value: newValue } })); */
            //setFocusedPrestation(undefined);
            //setSelectedApplications(undefined);
            setOpenApplicationChoice(false);
        }

      }

/*     const handleConfirmApplicationChoice = (application: PartsApplicationType) => {
        if(selectedPrestation && application){
            // On crée un nouvel objet PrestationRequest
            let req: PrestationRequest = {
                id: selectedPrestation.id,
                code: selectedPrestation.code,
                application: application
            }
            setCheckedPrestations([...checkedPrestations, req]);
        }
        setOpenApplicationChoice(false);
    } */

    /**
     * Personnalisation du style des Toogle Buttons
     * Note : il n'est posssible de le personnaliser directement au niveau
     * de l'objet "components" du theme.
     */
    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
            '&.Mui-disabled': {
                border: 0,
                backgroundColor: theme.palette.grey[100]
            },
            '&.Mui-selected': {
                backgroundColor: PALETTE_THIRD_COLOR_MAIN
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
                border: '1px solid rgba(0, 0, 0, 0.12)'
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }));

    const [expandedPrestations, setExpandedPrestations] = useState<number[]>([]); // Stocke les IDs des prestations avec opérations visibles

    // Fonction pour gérer l'expansion/collapse des opérations
    const handleToggleOperations = (prestationId: number) => {
        if (expandedPrestations.includes(prestationId)) {
            setExpandedPrestations(expandedPrestations.filter(id => id !== prestationId)); // Masquer les opérations
        } else {
            setExpandedPrestations([...expandedPrestations, prestationId]); // Afficher les opérations
        }
    };

    return (
        <>
         {garage &&
            <MovaDialog fullScreen={isMobile} open={localOpen} onClose={handleOnClose}
            leafImageColor="yellow" title={garage.name} titleStyle={dialogTitleStyle} sx={{ margin: 0}}  >
                {/** Motif du rendez-vous */}
                {!openApplicationChoice && <>

                    <Card variant='outlined'
                        sx={{ backgroundColor: 'transparent', borderLeft: 'none', borderRight: 'none',  mt:2, pb: 2, mb: 2, paddingLeft: 0 }}>
                        <CardContent sx={{ pl: 0 }}>
                            <Typography variant="h6" component="div" color={theme.palette.text.primary} style={flexCenter} sx={{ mb: 1}}>
                                <QuestionIcon sx={{ mr: 1 }} /><b>Motif du rendez-vous</b>
                            </Typography>
                            <Grid container sx={{ alignItems: 'center'}}>
                                {/** Le "[...garage.prestations]" permet de manipuler une copie du tableau inital car
                                 * la méthode sort() modifie le tableau d'origine. (merci ChatGPT)
                                 */}
                                {garage.prestations && [...garage.prestations]
                                    .filter((p) => p.active)    // On filtre sur les prestation actives
                                    .sort((a, b) => b.position - a.position)
                                    .map((prestation, index) => {

                                        // Trouver la prestation correspondante dans checkedPrestations
                                        const correspondingPrestation = checkedPrestations.find(p => p.id === prestation.id);

                                        // Obtenir les libellés des applications si elles existent
                                        const applicationLabels = correspondingPrestation && correspondingPrestation.applications
                                            ? getApplicationsShortLabels(correspondingPrestation.applications)
                                            : '';

                                            return (
                                                <React.Fragment key={index}>
                                                    <Grid item xs={11}>
                                                        <Typography variant="body1" color="text.primary" 
                                                            sx={{ textAlign: 'left',
                                                                cursor: (prestation.operations && prestation.operationsVisible) ? 'pointer' : 'default'
                                                            }} style={flexStart}
                                                            onClick={() => prestation.operationsVisible && handleToggleOperations(prestation.id)}
                                                        >
                                                            {/* Affichage d'un picto si défini */}
                                                            {importIcon(prestation.code) && (
                                                            <img
                                                                src={importIcon(prestation.code)}
                                                                style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginRight: '7px',
                                                                marginTop: '1px'
                                                                //opacity: 0.7,
                                                                }}
                                                            />
                                                            )}
                                                            {prestation.name} {applicationLabels && `(${applicationLabels})`}
                                                            {(prestation.operations && prestation.operationsVisible) && <ExpandMoreIcon />}
                                                        </Typography>
                                                            {/* Affichage des opérations si "operationsVisible" est vrai et si la prestation est "expandée" */}
                                                            {prestation.operations && prestation.operationsVisible && expandedPrestations.includes(prestation.id) && (
                                                                <Grid  container sx={{pl: 2, py: 1}}>
                                                                    {prestation.operations
                                                                        .sort((a, b) => a.name.localeCompare(b.name)) // Tri par ordre alphabétique
                                                                        .map(operation => (
                                                                        <Grid item xs={12} style={flexStart}>
                                                                            <Typography variant='body1' key={operation.id} color={theme.palette.text.secondary}
                                                                                sx={{ textAlign: 'start'}}>
                                                                                - {operation.name}
                                                                            </Typography>
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={(event) => handleCheckboxChange(event, prestation.id)}
                                                            checked={checkedPrestations.map(p => p.id).includes(prestation.id)}
                                                            disabled={checkedPrestations.map(p => p.id).includes(otherChoiceId)
                                                                && prestation.id !== otherChoiceId}
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            );
                                    })}

                                {checkedPrestations.map(p => p.id).includes(otherChoiceId) &&
                                    <Grid item xs={12}>
                                         <TextField
                                                id="event-otherReason"
                                                label="Précisez votre demande ..."
                                                value={otherReason}
                                                onChange={(e) => setOtherReason(e.target.value)}
                                                multiline
                                                rows={2}
                                                variant="outlined"
                                                required
                                                sx={{ mt: 1 }}
                                                fullWidth
                                                autoFocus
                                            />
                                    </Grid>}
                            </Grid>
                        </CardContent>
                    </Card>

                    <Button onClick={handleOnClickVehicleChoice} color="primary" sx={{ width: '90%', mt: 3 }} variant='contained'
                        disabled={checkedPrestations.length == 0 || (checkedPrestations.map(p => p.id).includes(otherChoiceId) && otherReason === '')}>
                        <NextIcon sx={{ mr: 1 }} />Suivant
                    </Button>
                </>}

                {/** Choix de l'application (uniquement pour certaines prestations) */}

                {openApplicationChoice && selectedPrestation && <Card variant='outlined'
                    sx={{ backgroundColor: 'transparent', mt:2, py: 2, mb: 2 }}>
                        <Typography variant="h6" component="div" color={theme.palette.text.primary} style={flexCenter} sx={{ px: 2, }}>
                            <b>{selectedPrestation?.name}</b>
                        </Typography>
                        <Typography sx={{  mt: 1, mb: 4 }} style={flexCenter}>
                        <   QuestionIcon sx={{ mr: 1, fontSize: '1.2rem' }} />Choisissez l'application
                        </Typography>

                        <StyledToggleButtonGroup
                            orientation={isMobile ? "vertical" : "horizontal"}
                            size="small"
                            value={selectedApplications}
                            //exclusive
                            onChange={(e, value:PartsApplicationType[]) => {
                                handleMultipleApplicationsChoice(value);
                            }}
                            aria-label="application-choice">
                                {selectedPrestation && selectedPrestation.operations && selectedPrestation.operations
                                    .filter((o, index, array) =>
                                        o.application && array.findIndex(op => op.application === o.application) === index
                                    )
                                    .sort((a, b) => {
                                        const labelA = getApplicationShortLabel(a.application);
                                        const labelB = getApplicationShortLabel(b.application);
                                        return labelB.localeCompare(labelA);
                                    })
                                    .map((o, index) => (
                                    <ToggleButton size="large" key={index+100} sx={{ width: 100 }}
                                        value={o.application ?? ""}>
                                        {getApplicationShortLabel(o.application)}
                                    </ToggleButton>
                                ))}
                        </StyledToggleButtonGroup>
                        <Grid container>
                            <Grid item xs={selectedPrestation?.multipleApplication ? 6 : 12}>
                                <Button onClick={handleCancelApplicationChoice} color="inherit" sx={{ width: '80%', mt: 3 }} variant='text'>
                                    Annuler
                                </Button>
                            </Grid>
                            {selectedPrestation?.multipleApplication &&
                                <Grid item xs={6}>
                                    <Button onClick={() => confirmApplicationsChoice(undefined)} color="primary" sx={{ width: '80%', mt: 3, color:theme.palette.primary.dark }} variant='text'>
                                        <b>Confirmer</b>
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                </Card>}
            </MovaDialog>
            }

            <AppointmentVehicleDialog
                    open={openAppointmentVehicle}
                    garage={garage}
                    prestations={checkedPrestations}
                    otherReason={otherReason}
                    onClose={handleOnCloseAppointmentVehicle} />

            {!isAuthenticated && <AppointmentCustomerDialog
                    open={openAppointmentCustomer}
                    garage={garage}
                    prestations={checkedPrestations}
                    otherReason={otherReason}
                    onClose={handleOnCloseAppointmentCustomer} />}

        </>
    );
}

export default AppointmentPrestationsDialog;
